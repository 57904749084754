<template>
  <v-container fluid tag="section">
    <base-material-card
      color="primary"
      inline
      icon="mdi-text-box-check-outline"
      class="ml-4 px-5 py-3"
    >
      <template v-slot:after-heading>
        <div class="display-2 font-weight-light">
          Detalhes Indicadores
        </div>
      </template>

      <v-spacer class="mt-5" />

      <v-row
        v-if="!(competencia_de || competencia_ate) || !relatorioSelecionado"
        class="mx-auto"
        no-gutters
        justify="center"
      >
        <div class="body-1">
          Selecione a competência e o relatório
        </div>
      </v-row>

      <v-row class="mx-auto" justify="space-between">
        <v-col cols="6" sm="3">
          <input-month
            label="Competência de"
            rules="min:7"
            :minMonth="'2023-01'"
            :selectedMonth="competencia_de"
            @monthSelected="setCompetenciaDe"
          />
        </v-col>
        <v-col cols="6" sm="3">
          <input-month
            label="Competência até"
            rules="min:7"
            :minMonth="'2023-01'"
            :selectedMonth="competencia_ate"
            @monthSelected="setCompetenciaAte"
          />
        </v-col>

        <v-col cols="auto">
          <v-select
            :items="relatorios"
            :value="relatorioSelecionado"
            @change="setRelatorioSelecionado"
            label="Relatório"
            class="mt-0 pt-1"
            hide-details
          />
        </v-col>
      </v-row>

      <v-row
        v-if="['Comercial', 'Serviços'].includes(relatorioSelecionado)"
        class="mx-auto"
      >
        <v-expansion-panels>
          <v-expansion-panel>
            <v-expansion-panel-header
              >Temas selecionados</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-row dense>
                <v-col v-if="temasEmSelecionamento.length < 1" cols="12">
                  <strong style="color: red;"
                    >Selecione pelo menos 1 tema</strong
                  >
                </v-col>
                <v-col class="pb-0 pt-0" cols="12">
                  <v-checkbox
                    v-model="selecionar_todos_temas"
                    label="Selecionar todos os temas"
                    hide-details
                    class="mt-0"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col
                  class="pb-0 pt-1"
                  md="2"
                  sm="4"
                  cols="6"
                  v-for="temas in temas_validos[relatorioSelecionado]"
                  :key="temas.slug"
                >
                  <v-checkbox
                    v-model="temasEmSelecionamento"
                    :label="temas.category"
                    hide-details
                    :value="temas.slug"
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="
                  temasSelecionadosIsDirty && temasEmSelecionamento.length > 0
                "
                class="mt-3"
                justify="center"
              >
                <v-btn
                  color="primary"
                  elevation="5"
                  small
                  :disabled="loadingDados"
                  @click="aplicarTemasSelecionados()"
                >
                  Aplicar temas
                </v-btn>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="relatorioSelecionado == 'Serviços'">
            <v-expansion-panel-header
              >Serviços selecionados</v-expansion-panel-header
            >
            <v-expansion-panel-content>
              <v-row dense>
                <v-col v-if="servicosEmSelecionamento.length < 1" cols="12">
                  <strong style="color: red;"
                    >Selecione pelo menos 1 serviço</strong
                  >
                </v-col>
                <v-col class="pb-0 pt-0" cols="12">
                  <v-checkbox
                    v-model="selecionar_todos_servicos"
                    label="Selecionar todos os serviços"
                    hide-details
                    class="mt-0"
                  />
                </v-col>
              </v-row>
              <v-row dense>
                <v-col
                  class="pb-0 pt-1"
                  md="2"
                  sm="4"
                  cols="6"
                  v-for="categoria in categoriasServicos"
                  :key="categoria.id"
                >
                  <v-checkbox
                    v-model="servicosEmSelecionamento"
                    :label="categoria.nome"
                    :value="categoria.id"
                  />
                </v-col>
              </v-row>
              <v-row
                v-if="
                  servicosSelecionadosIsDirty &&
                    servicosEmSelecionamento.length > 0
                "
                class="mt-3"
                justify="center"
              >
                <v-btn
                  color="primary"
                  elevation="5"
                  small
                  :disabled="loadingDados"
                  @click="aplicarServicosSelecionados()"
                >
                  Aplicar serviços
                </v-btn>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-row>

      <v-row
        v-if="['Comercial', 'Serviços'].includes(relatorioSelecionado)"
        dense
        justify="center"
      >
        <v-col cols="auto">
          <v-switch
            class="switch-tipo-visao"
            color="primary"
            dense
            hide-details
            :false-value="switchData[relatorioSelecionado].falseValue"
            :true-value="switchData[relatorioSelecionado].trueValue"
            :disabled="loadingDados"
            v-model="tipoVisao"
          >
            <template #prepend>
              <label
                class="v-label"
                :class="
                  tipoVisao == switchData[relatorioSelecionado].falseValue
                    ? 'text--primary'
                    : 'text--secondary font-weight-light'
                "
              >
                {{ switchData[relatorioSelecionado].labelFalseValue }}
              </label>
            </template>
            <template #append>
              <label
                class="v-label"
                :class="
                  tipoVisao == switchData[relatorioSelecionado].trueValue
                    ? 'text--primary'
                    : 'text--secondary font-weight-light'
                "
              >
                {{ switchData[relatorioSelecionado].labelTrueValue }}
              </label>
            </template>
          </v-switch>
        </v-col>
      </v-row>

      <v-row class="mx-auto mt-4" justify="space-between">
        <v-col sm="2" cols="2">
          <v-spacer
            v-if="relatorioSelecionado == 'Serviços' && tipoVisao == 'valores'"
          />
          <v-checkbox
            v-else
            label="Mostrar apenas erros"
            v-model="mostrarApenasErros"
            class="mt-0"
            hide-details
          />
        </v-col>
        <v-col
          v-if="relatorioSelecionado == 'Comercial' && tipoVisao == 'municipio'"
          cols="6"
          sm="3"
        >
          <v-text-field
            label="Município"
            class="py-0"
            hide-details
            clearable
            v-model="codigoMunicipio"
          />
        </v-col>
        <v-col
          v-if="(competencia_de || competencia_ate) && relatorioSelecionado"
          cols="auto"
        >
          <v-btn
            id="btn-exportar-xlsx"
            color="primary"
            dark
            elevation="1"
            relative
            text
            medium
            :loading="downloadingArquivoValidacoes"
            @click="downloadArquivoValidacoes()"
          >
            <v-icon left size="30px">
              mdi-microsoft-excel
            </v-icon>
            Exportar XLSX
          </v-btn>
        </v-col>
      </v-row>

      <comercial-v2-visao-detalhada
        v-if="relatorioSelecionado === 'Comercial'"
        ref="comercialV2VisaoDetalhada"
        :competencia_de="competencia_de"
        :competencia_ate="competencia_ate"
        :tipoVisao="tipoVisao"
        :indicadoresFieldsCategories="
          indicadoresFieldsCategories.fields_indicadores_comerciais_v2_categories
        "
        :authorizedFieldsCategories="
          userIndicadoresFieldsCategories.fields_indicadores_comerciais_v2_categories ||
            []
        "
        :temas_selecionados="temas_selecionados"
        :mostrarApenasErros="mostrarApenasErros"
        :authorizedCompanies="authorizedCompanies"
        @updateDataTable="getDadosValidacoes()"
        @updateLoadingDados="loadingDados = $event"
        @updateDownloadingArquivoValidacoes="
          downloadingArquivoValidacoes = $event
        "
        :codigoMunicipio="codigoMunicipio"
      />
      <servicos-v2-visao-detalhada
        v-else-if="relatorioSelecionado === 'Serviços'"
        ref="servicosV2VisaoDetalhada"
        :competencia_de="competencia_de"
        :competencia_ate="competencia_ate"
        :tipoVisao="tipoVisao"
        :temas_selecionados="temas_selecionados"
        :categoriasServicos="categoriasServicos"
        :authorizedServicosCategories="
          userIndicadoresFieldsCategories.servicos_categories || []
        "
        :servicos_selecionados="servicos_selecionados"
        :mostrarApenasErros="mostrarApenasErros"
        :authorizedCompanies="authorizedCompanies"
        @updateDataTable="getDadosValidacoes()"
        @updateLoadingDados="loadingDados = $event"
        @updateDownloadingArquivoValidacoes="
          downloadingArquivoValidacoes = $event
        "
      />
      <tecnicos-visao-detalhada
        v-else-if="relatorioSelecionado === 'Técnicos'"
        ref="tecnicosVisaoDetalhada"
        :competencia_de="competencia_de"
        :competencia_ate="competencia_ate"
        :authorizedFieldsTecAlCategories="
          userIndicadoresFieldsCategories.fields_indicadores_alimentadores_categories ||
            []
        "
        :authorizedFieldsTecSubCategories="
          userIndicadoresFieldsCategories.fields_indicadores_subestacoes_categories ||
            []
        "
        :authorizedFieldsTecLdCategories="
          userIndicadoresFieldsCategories.fields_indicadores_linhas_distribuicao_categories ||
            []
        "
        :mostrarApenasErros="mostrarApenasErros"
        :authorizedCompanies="authorizedCompanies"
        @updateDataTable="getDadosValidacoes()"
        @updateLoadingDados="loadingDados = $event"
        @updateDownloadingArquivoValidacoes="
          downloadingArquivoValidacoes = $event
        "
      />
    </base-material-card>

    <v-overlay :value="loadingDados" :opacity="0.85" z-index="210">
      <v-progress-circular indeterminate color="primary" size="64" />
    </v-overlay>
  </v-container>
</template>

<script>
  import IndicadoresServicosV2Service from "@/services/IndicadoresServicosV2Service.js";
  import UsersService from "@/services/UsersService.js";
  import { mapState, mapActions } from "vuex";

  export default {
    name: "ValidacoesVisaoDetalhada",
    components: {
      InputMonth: () => import("@/components/general/InputMonth.vue"),
      ComercialV2VisaoDetalhada: () =>
        import("@/components/validacoes/ComercialV2VisaoDetalhada.vue"),
      ServicosV2VisaoDetalhada: () =>
        import("@/components/validacoes/ServicosV2VisaoDetalhada.vue"),
      TecnicosVisaoDetalhada: () =>
        import("@/components/validacoes/TecnicosVisaoDetalhada.vue"),
    },
    data: () => ({
      relatorios: ["Comercial", "Serviços", "Técnicos"],
      temas_validos: {
        Comercial: [],
        Serviços: [
          { slug: "Realizados", category: "Realizados" },
          { slug: "Abertos", category: "Entrantes" },
          { slug: "Suspensos", category: "Suspensos" },
          { slug: "Pendentes", category: "Pendentes" },
          { slug: "Compensados", category: "Compensados" },
        ],
      },
      categoriasServicos: [],
      switchData: {
        Comercial: {
          trueValue: "consolidada",
          labelTrueValue: "Visão Consolidada",
          falseValue: "municipio",
          labelFalseValue: "Visão por Município",
        },
        Serviços: {
          trueValue: "valores",
          labelTrueValue: "Visualizar Valores",
          falseValue: "falhas",
          labelFalseValue: "Visualizar Falhas",
        },
      },
      selecionar_todos_temas: true,
      indicadoresFieldsCategories: {},
      userIndicadoresFieldsCategories: {},
      temas_selecionados: [],
      temasEmSelecionamento: [],
      temasSelecionadosIsDirty: false,
      selecionar_todos_servicos: true,
      servicos_selecionados: [1],
      servicosEmSelecionamento: [],
      authorizedCompanies: [],
      servicosSelecionadosIsDirty: false,
      tipoVisao: "consolidada",
      mostrarApenasErros: false,
      loadingDados: false,
      downloadingArquivoValidacoes: false,
      codigoMunicipio: "",
    }),
    computed: {
      ...mapState("parametrosPainelValidacoes", [
        "competencia_de",
        "competencia_ate",
        "relatorioSelecionado",
      ]),
      concatedArrFilters() {
        return [
          this.competencia_de,
          this.competencia_ate,
          this.tipoVisao,
          this.temas_selecionados,
          this.servicos_selecionados,
        ];
      },
    },
    async created() {
      await Promise.all([
        this.getCategoriasServicos(),
        this.getIndicadoresFieldsCategories(),
        this.getIndicadoresFieldsCategoriesByUser(),
        this.getAuthorizedCompanies(),
      ]).catch((err) => {
        console.error(err);
      });

      this.setSubParametros();
    },
    methods: {
      ...mapActions("parametrosPainelValidacoes", [
        "setCompetenciaDe",
        "setCompetenciaAte",
        "setRelatorioSelecionado",
      ]),
      setSubParametros() {
        if (this.relatorioSelecionado === "Comercial") {
          this.tipoVisao = this.switchData[this.relatorioSelecionado].trueValue;
          this.temasEmSelecionamento = this.temas_selecionados = this.temas_validos[
            "Comercial"
          ].map((tema) => tema.slug);
        } else if (this.relatorioSelecionado === "Serviços") {
          this.tipoVisao = this.switchData[
            this.relatorioSelecionado
          ].falseValue;
          this.temasEmSelecionamento = this.temas_selecionados = [
            "Realizados",
            "Abertos",
            "Suspensos",
            "Pendentes",
            "Compensados",
          ];
        }
      },
      getIndicadoresFieldsCategories() {
        return UsersService.getIndicadoresFieldsCategories()
          .then((res) => {
            this.indicadoresFieldsCategories = res;

            this.temas_validos[
              "Comercial"
            ] = this.indicadoresFieldsCategories.fields_indicadores_comerciais_v2_categories;
          })
          .catch((err) => {
            this.$toast.error("Erro ao recuperar temas dos indicadores.", "", {
              position: "topRight",
            });
            throw err;
          });
      },
      getIndicadoresFieldsCategoriesByUser() {
        // Dados necessários apenas para perfil Validador
        if (!this.$store.getters.getUserInfo.validador) {
          return;
        }

        return UsersService.getIndicadoresFieldsCategoriesByUser(
          this.$store.getters.getUserId
        )
          .then((res) => {
            this.userIndicadoresFieldsCategories = res;
          })
          .catch((err) => {
            this.$toast.error("Erro ao recuperar temas dos indicadores.", "", {
              position: "topRight",
            });
            throw err;
          });
      },
      getCategoriasServicos() {
        return IndicadoresServicosV2Service.getCategoriasServicos()
          .then((res) => {
            this.categoriasServicos = res;

            this.servicos_selecionados = this.servicosEmSelecionamento = this.categoriasServicos.map(
              (categoria) => categoria.id
            );
          })
          .catch((err) => {
            this.$toast.error("Erro ao recuperar categorias de serviços.", "", {
              position: "topRight",
            });
            throw err;
          });
      },
      getDadosValidacoes() {
        if (
          (this.relatorioSelecionado !== "Técnicos" &&
            this.temas_selecionados.length < 1) ||
          (this.relatorioSelecionado === "Serviços" &&
            this.servicos_selecionados.length < 1) ||
          this.competencia_de == undefined ||
          this.competencia_ate == undefined
        ) {
          return;
        }

        switch (this.relatorioSelecionado) {
          case "Comercial":
            this.$refs.comercialV2VisaoDetalhada.getDados(
              this.competencia_de,
              this.competencia_ate,
              this.temas_selecionados
            );
            break;
          case "Serviços":
            this.$refs.servicosV2VisaoDetalhada.getDados(
              this.competencia_de,
              this.competencia_ate,
              this.temas_selecionados,
              this.servicos_selecionados
            );
            break;
          case "Técnicos":
            this.$refs.tecnicosVisaoDetalhada.getDados(
              this.competencia_de,
              this.competencia_ate
            );
            break;
        }
      },
      aplicarTemasSelecionados() {
        this.temas_selecionados = this.temasEmSelecionamento;
        this.temasSelecionadosIsDirty =
          this.temasEmSelecionamento.toSorted().toString() !==
          this.temas_selecionados.toSorted().toString()
            ? true
            : false;
      },
      aplicarServicosSelecionados() {
        this.servicos_selecionados = this.servicosEmSelecionamento;
        this.servicosSelecionadosIsDirty =
          this.servicosEmSelecionamento.toSorted().toString() !==
          this.servicos_selecionados.toSorted().toString()
            ? true
            : false;
      },
      downloadArquivoValidacoes() {
        switch (this.relatorioSelecionado) {
          case "Comercial":
            this.$refs.comercialV2VisaoDetalhada.downloadArquivoValidacoes();
            break;
          case "Serviços":
            this.$refs.servicosV2VisaoDetalhada.downloadArquivoValidacoes();
            break;
          case "Técnicos":
            this.$refs.tecnicosVisaoDetalhada.downloadArquivoValidacoes();
            break;
        }
      },
      getAuthorizedCompanies() {
        UsersService.getAuthorizedCompaniesValidation()
          .then((res) => {
            this.authorizedCompanies = res;
          })
          .catch((err) => {
            this.$toast.error("Erro no download do arquivo.", "", {
              position: "topRight",
              timeout: 5000,
            });
            throw err;
          });
      },
    },
    watch: {
      concatedArrFilters() {
        this.getDadosValidacoes();
      },
      selecionar_todos_temas() {
        this.temasEmSelecionamento = this.selecionar_todos_temas
          ? this.temas_validos[this.relatorioSelecionado].map(
              (tema) => tema.slug
            )
          : [];
      },
      temasEmSelecionamento() {
        this.temasSelecionadosIsDirty =
          this.temasEmSelecionamento.toSorted().toString() !==
          this.temas_selecionados.toSorted().toString()
            ? true
            : false;
      },
      selecionar_todos_servicos() {
        this.servicosEmSelecionamento = this.selecionar_todos_servicos
          ? this.categoriasServicos.map((categoria) => categoria.id)
          : [];
      },
      servicosEmSelecionamento() {
        this.servicosSelecionadosIsDirty =
          this.servicosEmSelecionamento.toSorted().toString() !==
          this.servicos_selecionados.toSorted().toString()
            ? true
            : false;
      },
      relatorioSelecionado() {
        this.setSubParametros();
      },
    },
  };
</script>

<style>
  .switch-tipo-visao .v-input__control {
    width: 32px !important;
  }
  .switch-tipo-visao
    .v-input--selection-controls__input
    .v-input--switch__track,
  .switch-tipo-visao
    .v-input--selection-controls__input
    .v-input--switch__thumb {
    color: #003a63 !important;
  }
</style>
